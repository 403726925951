import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Design = () => {
  const [designData, setDesignData] = useState([]);

  useEffect(() => {
    fetch("/designData.json")
      .then((response) => response.json())
      .then((data) => setDesignData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <div className="p-6 bg-gray-50 mb-32">
      <h2 className="text-3xl font-bold mb-2 pt-32 text-left md:text-center">
        Design Gallery
      </h2>
      <p className="text-gray-600 mb-12 text-left md:text-center">
        If you're on the lookout for simple home interior designs, look no
        further than our end-to-end design services.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {designData.map((design) => (
          <Link key={design.id} to={`/details/${design.id}`}>
            <div className="relative overflow-hidden rounded-lg shadow-lg cursor-pointer hover:scale-[1.05] hover:duration-[1.25s]">
              <img
                src={design.imageUrl}
                alt={design.name}
                className="object-cover w-full h-64"
              />
              <div className="absolute bottom-0 left-0 w-full bg-transparent bg-opacity-50 p-4">
                <h3 className="text-lg font-semibold text-white">
                  {design.name}
                </h3>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Design;
