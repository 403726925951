import React, { useState } from "react";
import { Modal, Box, TextField, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const Section3 = () => {
  const [open, setOpen] = useState(false);
  const [selectedEstimate, setSelectedEstimate] = useState(null);
  const [formData, setFormData] = useState({ name: "", phone: "", email: "" });

  const estimates = [
    {
      title: "Essential Interiors",
      description: "For homes to be rented out",
      price: "₹5.1 Lakh",
      items: [
        { name: "Kitchen", cost: "₹1.6 Lakh" },
        { name: "Wardrobe", cost: "₹84,892" },
        { name: "Other Interiors", cost: "₹2.7 Lakh" },
      ],
      popular: false,
    },
    {
      title: "Comfort Interiors",
      description: "For first-time homeowners",
      price: "₹7.5 Lakh",
      items: [
        { name: "Kitchen", cost: "₹2.3 Lakh" },
        { name: "Wardrobe", cost: "₹1.1 Lakh" },
        { name: "Other Interiors", cost: "₹4.2 Lakh" },
      ],
      popular: true, // This will show the POPULAR badge
    },
    {
      title: "Luxury Interiors",
      description: "Best of design and style",
      price: "₹8.8 Lakh",
      items: [
        { name: "Kitchen", cost: "₹2.8 Lakh" },
        { name: "Wardrobe", cost: "₹1.3 Lakh" },
        { name: "Other Interiors", cost: "₹4.7 Lakh" },
      ],
      popular: false,
    },
  ];

  const handleOpen = (estimate) => {
    setSelectedEstimate(estimate);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedEstimate(null);
    setFormData({ name: "", phone: "", email: "" });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    toast.success("Your request has been submitted successfully!");
    handleClose();
  };

  return (
    <>
      <ToastContainer />
      <section className="container mx-auto px-6 py-8">
        <div className="bg-white border border-gray-300 py-4 px-6 rounded-lg mb-8 flex items-center justify-between">
          <p className="text-gray-700 font-semibold">
            What's Included{" "}
            <span className="text-gray-400 font-normal">
              (Get a price closer to your requirement, by changing the details)
            </span>
          </p>
          <p className="text-red-500 flex items-center space-x-2">
            <span>Kitchen, 1 Bedrooms, 7 Other Interiors</span>
            <span className="text-xl px-2 py-1 font-bold rounded-full border border-red-500 text-red-500 bg-white shadow-md hover:bg-red-500 hover:text-white transition-colors duration-300">
              +
            </span>
          </p>
        </div>

        {/* Dynamic Estimate Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {estimates.map((estimate, index) => (
            <div key={index} className="bg-white p-6 shadow-lg rounded-lg">
              <div className="flex justify-between items-center">
                <h3 className="text-xl font-bold">{estimate.title}</h3>
                {estimate.popular && (
                  <span className="px-2 py-1 bg-red-500 text-white text-xs rounded-md">
                    POPULAR
                  </span>
                )}
              </div>
              <p className="text-gray-500">{estimate.description}</p>
              <div className="flex justify-between">
                <p className="flex items-center text-lg">Starting From :</p>
                <p className="text-red-600 text-3xl font-semibold">
                  {estimate.price}
                </p>
              </div>

              <ul className="space-y-2 my-4">
                {estimate.items.map((item, idx) => (
                  <li key={idx} className="flex justify-between">
                    <span>{item.name}</span>
                    <span>{item.cost}</span>
                  </li>
                ))}
              </ul>
              <button
                onClick={() => handleOpen(estimate)}
                className="text-red-500 hover:underline"
              >
                View Details
              </button>
            </div>
          ))}
        </div>

        <Modal open={open} onClose={handleClose}>
          <Box className="bg-white p-4 rounded-lg shadow-lg max-w-3xl w-full mx-auto mt-20">
            {selectedEstimate && (
              <>
                <div className="flex justify-end mb-4">
                  <IconButton
                    onClick={handleClose}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* Left side: Estimate details */}
                  <div className="py-10">
                    <h2 className="text-3xl font-bold">
                      {selectedEstimate.title}
                    </h2>
                    <p className="text-gray-500">
                      {selectedEstimate.description}
                    </p>
                    <div className="flex justify-between">
                      <p className="flex items-center text-lg">
                        Starting From :
                      </p>
                      <p className="text-red-600 text-3xl font-semibold">
                        {selectedEstimate.price}
                      </p>
                    </div>
                    <ul className="space-y-2 my-4">
                      {selectedEstimate.items.map((item, idx) => (
                        <li key={idx} className="flex justify-between">
                          <span>{item.name}</span>
                          <span>{item.cost}</span>
                        </li>
                      ))}
                    </ul>
                  </div>

                  {/* Right side: Form */}
                  <div className="bg-gray-100 p-4 rounded-lg">
                    <h2 className="text-lg font-bold text-center">
                      Free Consultation
                    </h2>
                    <p className="text-center text-gray-600 mb-4">
                      Get one-to-one free consultation immediately
                    </p>
                    <form onSubmit={handleSubmit} className="space-y-4">
                      <TextField fullWidth label="Name*" variant="outlined" />
                      <TextField fullWidth label="Phone*" variant="outlined" />
                      <TextField fullWidth label="Email*" variant="outlined" />
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        style={{
                          backgroundColor: "#E87722",
                          color: "white",
                          padding: "12px 0",
                        }}
                      >
                        Get Quotes Now
                      </Button>
                    </form>
                  </div>
                </div>
              </>
            )}
          </Box>
        </Modal>
      </section>
    </>
  );
};

export default Section3;
