import React from "react";
import Nav from "../components/home1/Nav.jsx";
import OfferHero from "../components/Offer/OfferHero.jsx";
import Gifts from "../components/Offer/Gifts.jsx";
import ExclusiveGifts from "../components/Offer/ExclusiveGifts";
import LuxuryDesign from "../components/Offer/LuxuryDesign.jsx";
import PremiumGifts from "../components/Offer/PremiumGifts.jsx";
import Footer from "../components/home1/Footer.jsx";

function offer() {
  return (
    <div>
      <Nav />
      <OfferHero />
      <Gifts />
      <ExclusiveGifts />
      <LuxuryDesign />
      <PremiumGifts />
      <div className="mb-20">
        <Footer />
      </div>
    </div>
  );
}

export default offer;
