import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages';
import Home1 from './pages/home1';
import Home1RTL from './pages/home1-rtl';
import Home3 from './pages/home3_construction';
import Home4 from './pages/home4_personal_architect';
import Home5 from './pages/home5_residence';
import Home6 from './pages/home6_furniture';
import Home7 from './pages/home7_landscape';
import Home7360 from './pages/home7_landscape_360';


import AboutPage from './pages/innerpages/about';
import BlogPage from './pages/innerpages/blog';
import ContactPage from './pages/innerpages/contact';
import PortfolioPage from './pages/innerpages/portfolio';
import ServicesPage from './pages/innerpages/services';
import SinglePost from './pages/innerpages/single_post';
import SingleProject from './pages/innerpages/single_project';
import SingleProject2 from './pages/innerpages/single_project2';
import FloorplanSelection from './pages/FloorplanSection';
import Requirement from './pages/innerpages/requirement';
import Newhome from './pages/innerpages/newhome';
import Pincode from './pages/pincode';
import Gallery from './pages/gallery';
import CardDetails from './pages/CardDetails';
import Offer from "./pages/offer.jsx";
import Services from "./pages/services.jsx";
import Studio from "./pages/studio.jsx";
import Project from './pages/project.jsx';
import DesignProcess from "./pages/process.jsx";
import ReferAndEarn from './pages/ReferAndEarn.jsx';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/home1" element={<Home1 />} />
        <Route path="/home1-rtl" element={<Home1RTL />} />
        <Route path="/home3_construction" element={<Home3 />} />
        <Route path="/home4_personal_architect" element={<Home4 />} />
        <Route path="/home5_residence" element={<Home5 />} />
        <Route path="/home6_furniture" element={<Home6 />} />
        <Route path="/home7_landscape" element={<Home7 />} />
        <Route path="/home7_landscape_360" element={<Home7360 />} />
        <Route path="/details/:id" element={<CardDetails />} />


        <Route path="/innerpages/about" element={<AboutPage />} />
        <Route path="/innerpages/blog" element={<BlogPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/innerpages/portfolio" element={<PortfolioPage />} />
        <Route path="/innerpages/services" element={<ServicesPage />} />
        <Route path="/innerpages/single_post" element={<SinglePost />} />
        <Route path="/innerpages/single_project" element={<SingleProject />} />
        <Route
          path="/innerpages/single_project2"
          element={<SingleProject2 />}
        />
        <Route path="/floorplan" element={<FloorplanSelection />} />
        <Route path="/requirement" element={<Requirement />} />
        <Route path="/newhome" element={<Newhome />} />
        <Route path='/pincode' element={<Pincode />} />
        <Route path='/blog' element={<BlogPage />} />
        <Route path="/offer" element={<Offer />} />
        <Route path="/services" element={<Services />} />
        <Route path="/our-projects" element={<Studio />} />
        {/* <Route path="/project" element={<Project />} /> */}
        <Route path="/design-process" element={<DesignProcess />} />
        <Route path="/refer-and-earn" element={<ReferAndEarn />} />

      </Routes>
    </Router>
  );
}

export default App;
