import { useState } from "react";
import { Modal, Box, TextField, MenuItem, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../components/home1/Footer.jsx";
import Nav from "../components/home1/Nav.jsx";
import Section1 from "../components/home1/Newhome/Section1.jsx";

const InteriorSolutions = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    services: "",
  });

  const SERVICES_OPTIONS = [
    "Modular Kitchen", "Storage and Wardrobe", "Crockery Units", "Space Saving Furniture", 
    "TV Units", "Study Tables", "False Ceiling", "Lights", "Wallpaper", "Wall Paint", 
    "Bathroom", "Pooja Unit", "Foyer Designs", "Movable Furniture", "Kids Bedroom"
  ];

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    toast.success("Form submitted successfully!");
    setIsModalOpen(false);
  };

  const solutions = [
    { name: "Modular Kitchen", icon: "🍽️" }, { name: "Storage and Wardrobe", icon: "🚪" },
    { name: "Crockery Units", icon: "🍶" }, { name: "Space Saving Furniture", icon: "🪑" },
    { name: "TV Units", icon: "📺" }, { name: "Study Tables", icon: "💻" },
    { name: "False Ceiling", icon: "🏠" }, { name: "Lights", icon: "💡" },
    { name: "Wallpaper", icon: "🖼️" }, { name: "Wall Paint", icon: "🎨" },
    { name: "Bathroom", icon: "🚿" }, { name: "Pooja Unit", icon: "🛕" },
    { name: "Foyer Designs", icon: "🏡" }, { name: "Movable Furniture", icon: "🛋️" },
    { name: "Kids Bedroom", icon: "🛏️" }
  ];

  return (
    <>
      {/* <Nav /> */}
      <Section1 />
      <div className="text-center py-10">
        <h2 className="text-3xl font-semibold mb-12">End-to-end Interior Solutions</h2>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6 px-32 mb-20 space-y-4">
          {solutions.map((item, index) => (
            <div key={index} className="flex flex-col justify-between items-center min-h-28">
              <div className="">
                <span className="text-7xl">{item.icon}</span>
              </div>
              <div>
                <p className="mt-2 text-md text-[#d02424] font-[500]">{item.name}</p>
              </div>
            </div>
          ))}
        </div>
        <button
          onClick={() => setIsModalOpen(true)}
          className="mt-20 bg-orange-600 text-white px-6 py-3 rounded-lg text-sm font-semibold"
        >
          BOOK FREE DESIGN SESSION
        </button>

        <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <Box className="bg-white p-6 rounded-lg w-full md:w-1/4 mx-auto mt-80 relative">
            <IconButton className="absolute top-2 right-2" onClick={() => setIsModalOpen(false)}>
              <CloseIcon />
            </IconButton>
            <h3 className="text-xl font-bold text-center text-orange-600 mb-4">FREE CONSULTATION</h3>
            <form onSubmit={handleSubmit} className="space-y-3">
              <TextField
                fullWidth
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              <TextField
                fullWidth
                label="Phone"
                name="phone"
                type="tel"
                value={formData.phone}
                onChange={handleChange}
                required
              />
              <TextField
                fullWidth
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <TextField
                fullWidth
                select
                label="Select a service"
                name="services"
                value={formData.services}
                onChange={handleChange}
              >
                {SERVICES_OPTIONS.map((service, index) => (
                  <MenuItem key={index} value={service}>{service}</MenuItem>
                ))}
              </TextField>
              <Button 
                type="submit" 
                fullWidth 
                variant="contained"
                sx={{
                  backgroundColor: '#ea580c',
                  '&:hover': {
                    backgroundColor: '#c2410c',
                  },
                  color: 'white',
                  py: '12px',
                  fontSize: '0.875rem'
                }}
              >
                Book Free Design Session
              </Button>
            </form>
          </Box>
        </Modal>
      </div>
      <Footer />
    </>
  );
};

export default InteriorSolutions;