import { useState, useEffect } from "react";
import { FiPlus, FiMinus, FiCheck } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingBar from "react-top-loading-bar";
import { useSearchParams } from "react-router-dom";

const Requirement = () => {
  const [searchParams] = useSearchParams();

  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const interior = searchParams.get("interior");
  const purpose = searchParams.get("purpose");
  const bhk = searchParams.get("bhk");
  const plan = searchParams.get("plan");
  const [wardrobeCount, setWardrobeCount] = useState(0);
  const [entertainmentCount, setEntertainmentCount] = useState(1);
  const [studyUnitCount, setStudyUnitCount] = useState(1);
  const [crockeryUnitCount, setCrockeryUnitCount] = useState(1);
  const [isKitchenChecked, setIsKitchenChecked] = useState(true);

  useEffect(() => {
    if (bhk) {
      const bhkNumber = parseInt(bhk.charAt(0), 10);
      setWardrobeCount(bhkNumber);
    }
  }, [bhk]);

  const handleIncrement = (setter, value) => {
    setter(value + 1);
  };

  const handleDecrement = (setter, value) => {
    if (value > 0) {
      setter(value - 1);
    }
  };

  const handleClick = async () => {
    setProgress(50);
    // const url = 'http://localhost:5000/api/estimate';

    const url = "https://gharwala-5466fddd6458.herokuapp.com/api/estimate";

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          purpose,
          wardrobes: wardrobeCount,
          entertainmentUnits: entertainmentCount,
          studyUnits: studyUnitCount,
          crockeryUnits: crockeryUnitCount,
          kitchen: isKitchenChecked,
        }),
      });

      if (!response.ok) {
        toast.error(`${response.status}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setProgress(100);
        // throw new Error(`HTTP error! status: ${response.status}`);
      }
      // console.log('Estimate created successfully:', savedEstimate);
      setProgress(100);
      navigate("/newhome");
    } catch (error) {
      console.error("Error creating estimate quote:", error);
      throw error;
    }
  };

  return (
    <div className="flex flex-col justify-center items-center overflow-auto mb-5 bg-gray-50 min-h-screen">
      <LoadingBar
        color="#f11946"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <ToastContainer />
      <div className="text-2xl text-gray-700 mt-10 font-semibold">
        TELL US WHAT YOU NEED
      </div>
      <div className="mt-1 text-lg text-gray-500">STEP 3 OF 3</div>

      <div className="max-w-4xl w-full mx-auto p-8 bg-white rounded-lg shadow-md border border-gray-200 mt-8">
        <div className="grid grid-cols-2 gap-12">
          {/* Left side - Form */}
          <div className="border-r border-gray-300 pr-8">
            <h2 className="text-lg font-semibold mb-6 text-gray-800">
              Your requirements for{" "}
              {interior === "Commercial" ? <>{plan}</> : <>{bhk}</>}
            </h2>

            {/* Kitchen Option */}
            <div className="mb-8 flex items-center justify-between  py-2 border-b border-gray-300">
              <p className="font-medium text-lg text-gray-800">{interior}</p>
              <div
                className={`w-6 h-6 flex items-center justify-center border-2 ${
                  isKitchenChecked
                    ? "border-red-500 text-red-500"
                    : "border-gray-400"
                } rounded-full cursor-pointer`}
                onClick={() => setIsKitchenChecked(!isKitchenChecked)}
              >
                {isKitchenChecked && <FiCheck />}
              </div>
            </div>

            {/* Wardrobe Section */}
            {interior !== "Commercial" && (
              <div className="mb-8 grid grid-cols-2 gap-4 items-center py-2 border-b border-gray-300">
                <div>
                  <p className="font-medium text-lg text-gray-800">Wardrobe</p>
                  <p className="text-xs text-gray-500">
                    (Number of wardrobes can’t exceed the number of bedrooms)
                  </p>
                </div>
                <div className="flex justify-end items-center space-x-2">
                  <button
                    onClick={() =>
                      handleDecrement(setWardrobeCount, wardrobeCount)
                    }
                    className="p-1 border border-red-500 text-red-500 rounded"
                  >
                    <FiMinus />
                  </button>
                  <input
                    type="text"
                    value={wardrobeCount}
                    disabled
                    className="w-8 text-center text-gray-800 border border-red-500 bg-white rounded"
                  />
                  <button
                    onClick={() =>
                      handleIncrement(setWardrobeCount, wardrobeCount)
                    }
                    className="p-1 border border-red-500 text-red-500 rounded"
                  >
                    <FiPlus />
                  </button>
                </div>
              </div>
            )}

            {/* Entertainment Unit */}
            {interior != "Commercial" && (
              <div className="mb-8 grid grid-cols-2 gap-4 items-center py-2 border-b border-gray-300">
                <div>
                  <p className="font-medium text-lg text-gray-800">
                    Entertainment unit
                  </p>
                </div>
                <div className="flex justify-end items-center space-x-2">
                  <button
                    onClick={() =>
                      handleDecrement(setEntertainmentCount, entertainmentCount)
                    }
                    className="p-1 border border-red-500 text-red-500 rounded"
                  >
                    <FiMinus />
                  </button>
                  <input
                    type="text"
                    value={entertainmentCount}
                    disabled
                    className="w-8 text-center text-gray-800 border border-red-500 bg-white rounded"
                  />
                  <button
                    onClick={() =>
                      handleIncrement(setEntertainmentCount, entertainmentCount)
                    }
                    className="p-1 border border-red-500 text-red-500 rounded"
                  >
                    <FiPlus />
                  </button>
                </div>
              </div>
            )}

            {/* Study Unit */}
            {interior !== "Commercial" && (
              <div className="mb-8 grid grid-cols-2 gap-4 items-center py-2 border-b border-gray-300">
                <div>
                  <p className="font-medium text-lg text-gray-800">
                    Study unit
                  </p>
                </div>
                <div className="flex justify-end items-center space-x-2">
                  <button
                    onClick={() =>
                      handleDecrement(setStudyUnitCount, studyUnitCount)
                    }
                    className="p-1 border border-red-500 text-red-500 rounded"
                  >
                    <FiMinus />
                  </button>
                  <input
                    type="text"
                    value={studyUnitCount}
                    disabled
                    className="w-8 text-center text-gray-800 border border-red-500 bg-white rounded"
                  />
                  <button
                    onClick={() =>
                      handleIncrement(setStudyUnitCount, studyUnitCount)
                    }
                    className="p-1 border border-red-500 text-red-500 rounded"
                  >
                    <FiPlus />
                  </button>
                </div>
              </div>
            )}

            {/* Crockery Unit */}
            {interior !== "Commercial" && (
              <div className="mb-8 grid grid-cols-2 gap-4 items-center py-2 border-b border-gray-300">
                <div>
                  <p className="font-medium text-lg text-gray-800">
                    Crockery unit
                  </p>
                </div>
                <div className="flex justify-end items-center space-x-2">
                  <button
                    onClick={() =>
                      handleDecrement(setCrockeryUnitCount, crockeryUnitCount)
                    }
                    className="p-1 border border-red-500 text-red-500 rounded"
                  >
                    <FiMinus />
                  </button>
                  <input
                    type="text"
                    value={crockeryUnitCount}
                    disabled
                    className="w-8 text-center text-gray-800 border border-red-500 bg-white rounded"
                  />
                  <button
                    onClick={() =>
                      handleIncrement(setCrockeryUnitCount, crockeryUnitCount)
                    }
                    className="p-1 border border-red-500 text-red-500 rounded"
                  >
                    <FiPlus />
                  </button>
                </div>
              </div>
            )}
          </div>

          {/* Right side - Image and description */}
          <div className="flex flex-col items-center justify-center text-center">
            <img
              src="/home1/assets/img/kitchen.png"
              alt="Other Interior Products"
              className="w-48 h-48 mb-6"
            />
            <h3 className="text-xl font-medium mb-4 text-gray-800">
              Other Interior Products
            </h3>
            <p className="text-gray-600">
              Get interiors done for your living room, study, and foyer areas
              along with false ceilings, painting, and modular furniture.
            </p>
          </div>
        </div>
      </div>

      <div className="flex justify-between mt-10 gap-4 font-semibold">
        <button
          onClick={() => navigate("/floorplan")}
          className="px-12 py-3 bg-white text-red-500 border border-red-500 hover:bg-red-50 rounded-md transition duration-300"
        >
          Back
        </button>
        <button
          onClick={handleClick}
          className="px-12 py-3 bg-red-500 text-white hover:bg-red-600 rounded-md transition duration-300"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Requirement;
