import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Section1 from "../components/home1/Newhome/Section1";
import Footer from "../components/home1/Footer";
import { Modal, Box, IconButton } from "@mui/material";
import Navbar from "../components/home1/Navbar";
import CloseIcon from "@mui/icons-material/Close";

const servicesOptions = [
  "Modular Kitchen",
  "Storage and Wardrobe",
  "Crockery Units",
  "Space Saving Furniture",
  "TV Units",
  "Study Lamps",
  "False Ceiling",
  "Lights",
  "Wallpaper",
  "Wall Paint",
  "Bathroom",
  "Pooja Unit",
  "Foyer Designs",
  "Movable Furniture",
  "Kids Bedroom",
];

const CardDetails = () => {
  const { id } = useParams();
  const [design, setDesign] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    services: "",
  });
  const [open, setOpen] = useState(false);
  const [carouselImages, setCarouselImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    fetch("/designData.json")
      .then((response) => response.json())
      .then((data) => {
        const selectedDesign = data.find((item) => item.id === parseInt(id));
        setDesign(selectedDesign);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [id]);

  if (!design) {
    return <p className="text-center text-xl mt-10">Loading...</p>;
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Submitted:", formData);
    toast.success("Consultation booked successfully");
    handleCloseModal();
  };

  const handleOpenModal = (image) => {
    const clickedIndex = design.imageDetails.findIndex((img) => img === image);
    const reorderedImages = [
      image,
      ...design.imageDetails.slice(0, clickedIndex),
      ...design.imageDetails.slice(clickedIndex + 1),
    ];
    setCarouselImages(reorderedImages);
    setCurrentImageIndex(0);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setCarouselImages([]);
    setCurrentImageIndex(0);
  };

  return (
    <>
      <Section1 />
      <ToastContainer className="absolute top-4 right-0" autoClose={3000} />
      <div className="flex flex-col md:flex-row justify-center items-start p-4 gap-6 bg-gray-50 min-h-screen">
        {/* Left Card */}
        <div className="w-full md:w-2/3 p-6 bg-white shadow-lg rounded-lg">
          <h2 className="text-3xl font-bold mb-6 text-center">{design.name}</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {design.imageDetails.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={design.name}
                className="object-cover w-full h-64 rounded-lg shadow-lg cursor-pointer"
                onClick={() => handleOpenModal(image)}
              />
            ))}
          </div>
        </div>

        {/* Right Card (Form) */}
        <div className="w-full md:w-96 p-6 bg-white shadow-lg rounded-lg">
          <h3 className="text-xl font-bold text-center text-orange-600 mb-4">
            FREE CONSULTATION
          </h3>
          <p className="text-sm text-gray-600 text-center mb-4">
            Get one-to-one free consultation immediately
          </p>
          <form onSubmit={handleSubmit} className="space-y-4">
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Name*"
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
              required
            />
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Phone*"
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
              required
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email*"
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
              required
            />
            <select
              name="services"
              value={formData.services}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
            >
              <option value="">Select a Service</option>
              {servicesOptions.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
            
            <button
              type="submit"
              className="w-full p-3 bg-orange-600 text-white font-bold rounded-lg hover:bg-orange-700 transition"
            >
              Get Quotes Now
            </button>
          </form>
        </div>
      </div>

      <div className="pb-10">
        <Footer />
      </div>

      {/* Modal */}
      <Modal open={open} onClose={handleCloseModal}>
        <Box className="bg-white shadow-lg rounded-lg p-6 w-full max-w-4xl mx-auto mt-60">
          <div className="flex justify-end">
            <IconButton
              className="absolute top-2 right-2"
              onClick={handleCloseModal}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="flex md:flex-row flex-col">
            <div className="w-full md:w-1/2 p-4 relative">
              {carouselImages.length > 0 && (
                <>
                  <img
                    src={carouselImages[currentImageIndex]}
                    alt="Selected Design"
                    className="w-full h-96 object-cover rounded-lg"
                  />
                  <button
                    onClick={() =>
                      setCurrentImageIndex((prev) =>
                        prev === 0 ? carouselImages.length - 1 : prev - 1
                      )
                    }
                    className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-lg hover:bg-gray-100"
                  >
                    &lt;
                  </button>
                  <button
                    onClick={() =>
                      setCurrentImageIndex((prev) =>
                        prev === carouselImages.length - 1 ? 0 : prev + 1
                      )
                    }
                    className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-lg hover:bg-gray-100"
                  >
                    &gt;
                  </button>
                </>
              )}
            </div>
            <div className="w-full md:w-1/2 p-6">
              <h3 className="text-xl font-bold text-center text-orange-600 mb-4">
                FREE CONSULTATION
              </h3>
              <form onSubmit={handleSubmit} className="space-y-4">
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Name*"
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
                  required
                />
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Phone*"
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
                  required
                />
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email*"
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
                  required
                />

                <select
                  name="services"
                  value={formData.services}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-lg"
                >
                  <option value="">Select a Service</option>
                  {servicesOptions.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <button
                  type="submit"
                  className="w-full p-3 bg-orange-600 text-white font-bold rounded-lg hover:bg-orange-700 transition"
                >
                  Get Quotes Now
                </button>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default CardDetails;
