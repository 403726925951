import React from 'react';
import { Link } from 'react-router-dom';
// import DesignerCard from "../designs/card3.tsx";
// import ProjectCard from "../designs/card2.tsx";
// import DesignExperience from "../designs/card1.tsx";
import Nav from '../../components/home1/Nav';
import Footer from '../../components/home1/Footer';

function DesignBlogPage() {
  const categories = [
    { name: "Home Decor", color: "text-blue-600" },
    { name: "Color Schemes", color: "text-orange-500" },
    { name: "Furniture", color: "text-green-600" },
    { name: "Lighting", color: "text-purple-600" },
    { name: "Sustainable Design", color: "text-pink-600" },
    { name: "Minimalism", color: "text-red-600" },
    { name: "Vintage", color: "text-gray-600" },
    { name: "Commercial Spaces", color: "text-blue-400" },
  ];

  const articles = [
    {
      id: 1,
      category: "TRENDS",
      toptitle: "Sophia Reynolds",
      title: "Top Interior Design Trends for 2024: From Biophilic to Smart Homes",
      date: "September 24, 2024",
      author: "Sophia Reynolds",
      description: "Explore the emerging interior design trends shaping modern living spaces in 2024, including sustainable materials and tech integration.",
      imageUrl: "/images/False_Cieing_Design/false-ceil1.jpg",
      header: "Modern interior design is evolving with new approaches to space utilization and aesthetics.",
      intro: "2024 brings innovative design concepts that blend functionality with artistic expression.",
      paragraphs: [
        "Contemporary interior design emphasizes the harmony between nature and technology...",
        "From adaptive furniture to eco-friendly materials, discover what's defining modern interiors..."
      ],
      sections: [
        {
          heading: "Sustainable Materials",
          content: [
            "Bamboo and reclaimed wood are becoming staples in modern furniture design...",
            "Low-VOC paints and recycled textiles are revolutionizing eco-conscious decor..."
          ]
        }
      ]
    },
    {
      id: 2,
      category: "COLOR SCHEMES",
      toptitle: "Michael Chen",
      title: "Mastering Color Psychology in Residential Spaces",
      date: "September 20, 2024",
      author: "Michael Chen",
      description: "Learn how to create emotional impact through strategic color selection in home interiors.",
      imageUrl: "/images/bedroom/Kids_Bedroom/kids-bed1.jpg",
      header: "Colors shape our perception of space and influence mood",
      intro: "Strategic color combinations can transform any living area",
      paragraphs: [
        "Discover how contrasting hues and tonal variations affect spatial perception...",
        "2024's trending palettes blend warm neutrals with bold accent colors..."
      ],
      sections: [
        {
          heading: "Creating Depth with Color",
          content: [
            "Use darker shades to create intimate spaces in large rooms...",
            "Vertical color blocking techniques for visual interest..."
          ]
        }
      ]
    },
    {
      id: 3,
      category: "LIGHTING",
      toptitle: "Emma Watanabe",
      title: "Innovative Lighting Solutions for Modern Interiors",
      date: "September 18, 2024",
      author: "Emma Watanabe",
      description: "Transform your space with layered lighting techniques and smart home integration.",
      imageUrl: "/images/living_area/living-area1.jpg",
      header: "Lighting as the cornerstone of interior ambiance",
      intro: "Beyond illumination: Lighting as architectural element",
      paragraphs: [
        "Modern lighting design combines functionality with sculptural aesthetics...",
        "Explore hidden LED solutions and programmable smart systems..."
      ],
      sections: [
        {
          heading: "Three-Point Lighting Strategy",
          content: [
            "Combining ambient, task, and accent lighting...",
            "Integrating natural light with artificial sources..."
          ]
        }
      ]
    },
    // {
    //   id: 4,
    //   category: "FURNITURE",
    //   toptitle: "Luca Marino",
    //   title: "The Future of Adaptive Furniture Design",
    //   date: "September 16, 2024",
    //   author: "Luca Marino",
    //   description: "Space-saving solutions and multi-functional furniture for urban living.",
    //   imageUrl: "/images/Master_Bedroom/master-bedroom1.jpg",
    //   header: "Redefining furniture for compact living spaces",
    //   intro: "Innovative designs that maximize functionality without compromising style",
    //   paragraphs: [
    //     "Transformable pieces that adapt to daily needs...",
    //     "Modular systems for flexible space configuration..."
    //   ],
    //   sections: [
    //     {
    //       heading: "Smart Materials",
    //       content: [
    //         "Self-healing surfaces and weight-sensitive components...",
    //         "Eco-friendly composites with enhanced durability..."
    //       ]
    //     }
    //   ]
    // },
    {
      id: 5,
      category: "SUSTAINABLE DESIGN",
      toptitle: "Priya Kapoor",
      title: "Eco-Conscious Materials Revolutionizing Interior Design",
      date: "September 5, 2024",
      author: "Priya Kapoor",
      description: "Sustainable alternatives transforming the design industry",
      imageUrl: "/images/modular_kitchen/modular-kitchen4.jpg",
      header: "Green design meets luxury aesthetics",
      intro: "Innovative materials creating sustainable yet stylish spaces",
      paragraphs: [
        "From mushroom-based acoustic panels to recycled glass countertops...",
        "How circular design principles are shaping modern interiors..."
      ],
      sections: [
        {
          heading: "Renewable Resources",
          content: [
            "Fast-growing bamboo applications...",
            "Cork as versatile flooring and wall solution..."
          ]
        }
      ]
    },
    {
      id: 6,
      category: "COMMERCIAL SPACES",
      toptitle: "James Fitzgerald",
      title: "Biophilic Design in Corporate Environments",
      date: "August 30, 2024",
      author: "James Fitzgerald",
      description: "Enhancing workplace productivity through nature-inspired design",
      imageUrl: "/images/living_area/living-area1.jpg",
      header: "Bringing the outdoors into workspaces",
      intro: "Creating healthier and more productive office environments",
      paragraphs: [
        "Living walls and natural material integration...",
        "Natural light optimization and airflow management..."
      ],
      sections: [
        {
          heading: "Wellness-Oriented Design",
          content: [
            "Ergonomic considerations...",
            "Sensory engagement through textures and scents..."
          ]
        }
      ]
    }
  ];

  const createSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/[^a-z0-9 -]/g, "")
      .replace(/\s+/g, "-")
      .replace(/-+/g, "-");
  };

  const ArticleCard = ({ article }) => (
    <div className="bg-transparent mb-6 flex flex-col sm:flex-row border-b border-slate-300 py-4">
      <div className="relative mb-4 sm:mb-0 sm:mr-6 w-full sm:w-[50%] h-64">
        <div className="absolute top-5 left-4 rounded-md py-1 px-1 text-sm bg-white font-semibold text-gray-500 z-10">
          {article.category}
        </div>
        <img
          className="w-full h-full object-cover rounded-xl"
          src={article.imageUrl}
          alt={`${article.title} image`}
          width={640}
          height={256}
          layout="responsive"
        />
      </div>

      <div className="flex-1 overflow-x-visible">
        <h6 className="text-slate-500 font-semibold text-xs">
          {article.author} on {article.date}
        </h6>
        <h3 className="mt-2 text-lg font-bold text-[rgb(41,41,75)]">
          {article.title}
        </h3>
        <p className="mt-2 text-sm text-gray-400">{article.description}</p>
        <div className="mt-4">
          <Link href={`/designs/${createSlug(article.title)}`}>
            <button className="bg-gradient-to-r from-indigo-400 to-indigo-600 text-white font-semibold py-2 px-4 rounded-lg shadow-md hover:from-indigo-500 hover:to-indigo-700 transition-colors duration-300">
              Explore Design
            </button>
          </Link>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Nav />
      <div className="px-8">
        {/* Header Section */}
        <div className="bg-white px-4 py-12 border-b border-slate-300">
          <div className="text-center">
            <h1 className="text-6xl font-bold text-[rgb(41,41,75)]">
              Design <span className="text-indigo-500">Inspirations</span>:
              Transforming Spaces, Creating
              <br /> Timeless Experiences
            </h1>
            <p className="mt-4 text-lg text-[rgb(105,105,129)]">
              Gharwala Interior brings you the ultimate resource for interior innovation! Explore
              <br />
              curated content on spatial design, decor trends, and architectural solutions.
            </p>
          </div>
        </div>

        {/* Design Categories */}
        {/* <div className="bg-white py-12 px-40">
          <div className="text-center">
            <h2 className="text-sm font-semibold text-gray-500">
              Explore Design Categories
            </h2>
            <div className="mt-6 flex justify-center flex-wrap gap-6">
              {categories.map((category) => (
                <div
                  key={category.name}
                  className="flex items-center space-x-2 bg-white rounded-full shadow-[0_4px_10px_rgba(99,102,241,0.2)] px-6 py-3 hover:shadow-[rgba(99,102,241,0.4)] cursor-pointer transition"
                >
                  <span className={`text-2xl ${category.color}`}></span>
                  <span className="text-lg font-medium text-gray-700">
                    {category.name}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div> */}

        {/* Main Content */}
        <div className="flex py-4 sm:px-6 lg:px-4 w-full">
          {/* Articles Section */}
          <div className="w-full md:w-[120%]">
            <div className="max-w-full mx-auto">
              {articles.map((article) => (
                <ArticleCard key={article.id} article={article} />
              ))}
            </div>
          </div>

          {/* Sidebar */}
          {/* <div className="hidden md:block md:w-[40%] ml-8">
            <div>
              <DesignerCard />
            </div>
            <div className="mt-10">
              <ProjectCard
                category="Space Planning"
                author="Isabella Rossi"
                date="July 7, 2024"
                title="Maximizing Small Spaces: Creative Solutions for Urban Apartments"
                imageUrl="https://designhub.example.com/images/small-space-design.webp"
              />
            </div>
            <div className="mt-10">
              <DesignExperience />
            </div>
          </div> */}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DesignBlogPage;