import React from 'react'

function LuxuryDesign() {
  return (
    <div className='relative md:bottom-52'>
      <div className='flex justify-center md:text-left text-center'>
        <p className='font-[600] md:text-[35px] text-[24px]'>Luxury Design, Special <span className='text-[#D02424]'>Gifts</span></p>
      </div>

      <div className='mt-24 flex md:flex-row flex-col md:gap-0 gap-10 justify-between md:px-10 px-6'>
        <img src="/images/hero/card1.png" alt="img" />
        <img src="/images/hero/card2.png" alg="img" />
        <img src="/images/hero/card3.png" alt="img" />
      </div>
    </div>
  )
}

export default LuxuryDesign
