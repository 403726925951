import React from 'react'
import Services from '../components/home1/Services'
import Navbar2 from '../components/innerpage/Navbar2'

const Gallery = () => {
  return (
    <div>
      <Navbar2 />
       <Services />

       <div className="projects-content">
        <div className="projects-slider">
          <div className="swiper-wrapper">
            
              <div  className="swiper-slide" >
                <a href="#" className="project-card">
                  <div className="info">
                    <h6 className="fsz-13 text-uppercase mb-3">
                     fe re1 11dfc ddf df
                    </h6>
                    <h3 className="fsz-28 text-capitalize fw-400">
                     red
                    </h3>
                  </div>
                </a>
              </div>
       
          </div>
          <div className="swiper-button-next"></div>
          <div className="swiper-button-prev"></div>
        </div>
        <div className="glry-img">
          
            <img
       
        
              src={"https://res.cloudinary.com/dtbvpj2r6/image/upload/v1733284680/False%20Cieing%20Design/p7rrcdupezkyz8ikffhg.jpg"}
              alt=""
              className={`tab-img `}
            />
   
        </div>
      </div>
    </div>
  )
}

export default Gallery