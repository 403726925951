import React, { useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../components/home1/Footer.jsx";
import Nav from "../components/home1/Nav.jsx";
import Section1 from "../components/home1/Newhome/Section1.jsx";

const studioVideo = { id: 1, name: "Studio Tour", video: "/images/video/studio.mp4" };

function Studio() {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    interestedIn: "",
  });

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setIsPlaying(true);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    toast.success("Form submitted successfully!");
    setFormData({ name: "", phone: "", email: "", interestedIn: "" });
  };

  return (
    <div className="overflow-hidden">
      {/* <Nav /> */}
      <Section1 />
      <div className="bg-gray-100 py-10 px-5 md:px-20 flex flex-col md:flex-row items-center md:items-start gap-10">
        
        {/* Video Section */}
        <div className="relative w-full md:w-2/3">
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <video
              ref={videoRef}
              src={studioVideo.video}
              className="w-full h-80 object-cover"
              controls={isPlaying}
            />
            {!isPlaying && (
              <button
                onClick={handlePlay}
                className="absolute inset-0 flex items-center justify-center bg-black text-white font-semibold text-lg px-6 py-3 rounded-lg hover:bg-opacity-75 transition"
              >
                ▶ Play Video
              </button>
            )}
          </div>
          <h3 className="text-lg font-semibold text-center py-3">
            {studioVideo.name}
          </h3>
        </div>

        {/* Sticky Form */}
        <div className="bg-white p-6 rounded-lg shadow-lg md:w-80 lg:w-96 md:sticky md:top-20 self-start">
          <h2 className="text-xl font-bold text-orange-500 text-center mb-4">
            FREE CONSULTATION
          </h2>
          <p className="text-gray-600 text-center mb-4">
            Get expert advice on setting up your studio
          </p>
          <form className="space-y-4" onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Name*"
              value={formData.name}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg"
              required
            />
            <input
              type="text"
              name="phone"
              placeholder="Phone*"
              value={formData.phone}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg"
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email*"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg"
              required
            />
            <input
              type="text"
              name="interestedIn"
              placeholder="Interested In"
              value={formData.interestedIn}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg"
            />
            <button className="w-full bg-orange-500 text-white py-2 rounded-lg font-semibold">
              Get Quotes Now
            </button>
          </form>
        </div>
      </div>
      <Footer />
      <ToastContainer position="top-center" autoClose={3000} hideProgressBar closeOnClick />
    </div>
  );
}

export default Studio;
