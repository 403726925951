import React from "react";
import { useState } from "react";
import {
  Modal,
  Box,
  TextField,
  MenuItem,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Form({ isModalOpen, setIsModalOpen }) {
//   const [localModalOpen, setLocalModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    services: "",
  });

  const SERVICES_OPTIONS = [
    "Modular Kitchen",
    "Storage and Wardrobe",
    "Crockery Units",
    "Space Saving Furniture",
    "TV Units",
    "Study Tables",
    "False Ceiling",
    "Lights",
    "Wallpaper",
    "Wall Paint",
    "Bathroom",
    "Pooja Unit",
    "Foyer Designs",
    "Movable Furniture",
    "Kids Bedroom",
  ];

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    toast.success("Form submitted successfully!");
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Box className="bg-white p-6 rounded-lg w-full md:w-1/4 mx-auto mt-80 relative">
          <IconButton
            className="absolute top-2 right-2"
            onClick={() => setIsModalOpen(false)}
          >
            <CloseIcon />
          </IconButton>
          <h3 className="text-xl font-bold text-center text-orange-600 mb-4">
            FREE CONSULTATION
          </h3>
          <form onSubmit={handleSubmit} className="space-y-3">
            <TextField
              fullWidth
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <TextField
              fullWidth
              label="Phone"
              name="phone"
              type="tel"
              value={formData.phone}
              onChange={handleChange}
              required
            />
            <TextField
              fullWidth
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <TextField
              fullWidth
              select
              label="Select a service"
              name="services"
              value={formData.services}
              onChange={handleChange}
            >
              {SERVICES_OPTIONS.map((service, index) => (
                <MenuItem key={index} value={service}>
                  {service}
                </MenuItem>
              ))}
            </TextField>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                backgroundColor: "#ea580c",
                "&:hover": {
                  backgroundColor: "#c2410c",
                },
                color: "white",
                py: "12px",
                fontSize: "0.875rem",
              }}
            >
              Book Free Consultation
            </Button>
          </form>
        </Box>
      </Modal>
    </>
  );
}

export default Form;
