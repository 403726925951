import React, { useState } from "react";
import Nav from "../components/home1/Nav";
import Footer from "../components/home1/Footer";
import Form from "../components/Form/Form";
import { motion } from "framer-motion";
import Section1 from "../components/home1/Newhome/Section1.jsx";

export default function Process() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      {/* <Nav /> */}
      <Section1 />
      <div className="bg-gray-100 min-h-[90vh] py-20">
        <motion.h1
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-4xl md:text-5xl font-bold text-gray-800 text-center mb-8"
        >
          Our Design Process
        </motion.h1>

        <div className="flex flex-col md:flex-row justify-center items-center gap-6 md:px-32 px-6">
          {/* Left Section */}
          <div className="bg-orange-100 p-6 rounded-xl max-w-sm min-h-[360px]">
            <h2 className="text-2xl font-semibold text-black">
              Complete Design Services
            </h2>
            <p className="text-gray-700 mt-2">
              With an emphasis on details, extraordinary designs, and
              exceptional customer service, we bring your dreams to life.
            </p>
            <button
              onClick={() => setIsModalOpen(true)}
              className="text-orange-500 font-semibold mt-4 inline-flex items-center"
            >
              KNOW MORE →
            </button>
          </div>

          {/* Right Section - Service Cards */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {/* Design Card */}
            <div className="bg-white p-6 rounded-xl shadow-md text-center">
              <img
                src="/images/process/design-icon.png"
                alt="Design"
                className="mx-auto h-40 w-full mb-4"
              />
              <h3 className="font-bold text-black">We Design</h3>
              <p className="text-gray-600 mt-2">
                From completed homes to modular kitchens, and storage to decor,
                our top interior designers create spaces that match your vision.
              </p>
            </div>

            {/* Execute Card */}
            <div className="bg-white p-6 rounded-xl shadow-md text-center">
              <img
                src="/images/process/execute-icon.png"
                alt="Execute"
                className="mx-auto h-40 w-full mb-4"
              />
              <h3 className="font-bold text-black">We Execute</h3>
              <p className="text-gray-600 mt-2">
                We follow a meticulous planning approach with detail-driven
                designs for interiors of your homes.
              </p>
            </div>

            {/* Manage Card */}
            <div className="bg-white p-6 rounded-xl shadow-md text-center">
              <img
                src="/images/process/manage-icon.png"
                alt="Manage"
                className="mx-auto h-40 w-full mb-4"
              />
              <h3 className="font-bold text-black">We Manage</h3>
              <p className="text-gray-600 mt-2">
                Our top interior designers spearhead quality assurance by
                extending support after the execution of home projects.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Form isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />

      <div className="pb-20">
        <Footer />
      </div>
    </>
  );
}
