import React, { useState } from "react";
import { Modal, Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TwitterIcon from "@mui/icons-material/Twitter";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";

const ReferAndEarn = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <section className="bg-orange-50">
      <div  style={{ backgroundImage: "url('/images/refer/refer-hero3.jpg')" }} className="md:py-20 py-10 md:px-32 px-6 items-center gap-10 bg-cover bg-no-repeat">
        {/* Left Content */}
        <div className="md:w-1/2 text-start md:text-left">
          <h2 className="text-white text-3xl md:text-5xl font-bold">
            Refer & Earn Rewards!
          </h2>
          <p className="text-gray-700 mt-4 text-lg md:text-lg">
            Invite your friends and family to experience our top-notch interior design services. Earn exclusive rewards for every successful referral.
          </p>
          <p className="text-white text-xl font-semibold mt-2">
            Earn up to ₹11,000 per referral!
          </p>
          <button
            className="mt-10 bg-orange-500 hover:bg-orange-600 text-white px-6 py-3 rounded-lg text-lg font-medium"
            onClick={handleOpen}
          >
            Refer Now →
          </button>
        </div>
  
      </div>

      {/* Modal */}
      <Modal open={open} onClose={handleClose}>
        <Box
          className="bg-white p-6 rounded-lg shadow-xl w-96 mx-auto mt-20 relative"
          sx={{ outline: "none", borderRadius: "10px" }}
        >
          {/* Close Button */}
          <IconButton
            sx={{ position: "absolute", top: 8, right: 8, color: "orange" }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>

          {/* Modal Content */}
          <div className="border-b pb-2 mb-4">
            <span className="text-black text-lg font-semibold">Referral Link</span>
          </div>

          <div className="text-gray-700 mb-2">Generate your referral link:</div>

          <input
            type="text"
            placeholder="Your Phone Number"
            className="w-full border px-3 py-3 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
          />

          <button className="w-full bg-orange-500 hover:bg-orange-600 text-white py-3 rounded-md font-medium mt-3 transition duration-300">
            GENERATE LINK
          </button>

          <div className="text-gray-600 mt-2">Share the good word</div>

          {/* Social Media Icons */}
          <div className="flex justify-center gap-4 mt-2">
            <IconButton sx={{ color: "#ff481f", bgcolor: "#f5f5f5" }}>
              <FacebookIcon />
            </IconButton>
            <IconButton sx={{ color: "#ff481f", bgcolor: "#f5f5f5" }}>
              <TwitterIcon />
            </IconButton>
            <IconButton sx={{ color: "#ff481f", bgcolor: "#f5f5f5" }}>
              <WhatsAppIcon />
            </IconButton>
            <IconButton sx={{ color: "#ff481f", bgcolor: "#f5f5f5" }}>
              <EmailIcon />
            </IconButton>
          </div>
        </Box>
      </Modal>
    </section>
  );
};

export default ReferAndEarn;
