import React from 'react'
import Footer from "../components/home1/Footer.jsx";
import Nav from "../components/home1/Nav.jsx";
import ReferHero from '../components/refer/ReferHero.jsx';
import ReferProcess from "../components/refer/ReferProcess.jsx";
import Section1 from "../components/home1/Newhome/Section1.jsx";

function ReferAndEarn() {
  return (
    <>
     {/* <Nav /> */}
     <Section1 />
     <div>
        <ReferHero />
        <ReferProcess />
     </div>
     <div className='pb-20'>
       <Footer />
     </div>
    </>
  )
}

export default ReferAndEarn
