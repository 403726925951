import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";

let API_KEY = "AIzaSyBkX4L3NKvRWF1R2c9ga5AQZR6Qj5nWHx4";

const Pincode = () => {
  const [district, setDistrict] = useState(null);
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const getLocLatLng = async () => {
    try {
      if (!district) return null;
      const results = await geocodeByAddress(district.label);
      const { lat, lng } = await getLatLng(results[0]);
      const locationName = results[0]?.formatted_address;

      console.log("Successfully got latitude, longitude, and location", {
        lat,
        lng,
        locationName,
      });

      return { lat, lng, locationName };
    } catch (err) {
      console.log("Error fetching location data:", err);
      return null;
    }
  };

  const handleNext = () => {
    if (district) {
      navigate("/floorplan");
    } else {
      setError("Please select a city before proceeding.");
    }
  };

  return (
    <div className="p-8 bg-gradient-to-br from-gray-100 to-white min-h-screen flex items-center justify-center">
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-lg w-full transition-transform transform hover:scale-105 duration-300">
        <h2 className="text-3xl font-semibold mb-4 text-center text-red-600">
          LET'S GET STARTED
        </h2>
        <p className="text-sm text-gray-500 text-center mb-8">STEP 1 OF 3</p>

        {/* City Selection */}
        <div className="mb-6">
          <label
            htmlFor="pincode"
            className="block text-lg font-medium mb-2 text-gray-700"
          >
            Enter Your City
          </label>
          <GooglePlacesAutocomplete
            apiKey={API_KEY}
            autocompletionRequest={{
              componentRestrictions: {
                country: ["in"],
              },
            }}
            selectProps={{
              value: district,
              onChange: (value) => {
                setDistrict(value);
                setError(""); // Clear error on valid selection
              },
              placeholder: "Enter City, Locality",
              className: "location-input",
              isClearable: true, // Allows clearing selection
            }}
          />
        </div>

        {/* Illustration and Text */}
        <div className="flex flex-col items-center justify-center mb-8">
          <img
            src="/home1/assets/img/head.png"
            alt="Building illustration"
            className="h-32 mb-4 rounded-md shadow-lg transition-transform transform hover:scale-110 duration-300"
          />
          <h3 className="text-lg font-medium text-center text-gray-800">
            Your Ideas. Our Expertise.
          </h3>
          <p className="text-gray-600 text-center mt-2">
            Our 600+ design experts use state-of-the-art 3D design technology,
            SpaceCraft, to ensure that you get the perfect designs for your
            home. Wait no more! Start your home interiors journey with us.
          </p>
        </div>

        {/* Next Button */}
        <button
          onClick={handleNext}
          className="w-full py-3 bg-red-600 text-white rounded-lg shadow-lg hover:bg-red-700 transition duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
        >
          Next
        </button>

        {/* Error Popup */}
        {error && (
          <div className="absolute top-0 left-1/2 transform -translate-x-1/2 mt-4 bg-red-500 text-white py-3 px-4 rounded-lg shadow-lg items-center gap-4 animate-fadeIn">
            <div className="flex justify-end">
              <button
                onClick={() => setError("")}
                className="text-white flex items-center justify-center text-sm font-bold"
              >
                ✕
              </button>
            </div>
            <span>{error}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Pincode;
