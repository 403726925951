import { Briefcase, Users, Building, Ruler } from "lucide-react";

const Ribbon = () => {
  const stats = [
    { icon: <Briefcase size={50} />, value: "100+", label: "Interior Projects" },
    { icon: <Users size={50} />, value: "20+", label: "Design Experts" },
    { icon: <Building size={50} />, value: "2 Cities", subLabel: "1 Countries" },
    { icon: <Ruler size={50} />, value: "30k+", label: "Design Options" },
  ];

  return (
    <div className="bg-gray-100 py-6">
      <div className="max-w-7xl mx-auto flex flex-wrap justify-between items-center px-4 lg:px-8">
        {stats.map((stat, index) => (
          <div
            key={index}
            className="flex flex-col md:flex-row md:gap-3 md:justify-center items-center text-center px-4 w-full md:mt-0 mt-4 sm:w-1/2 md:w-1/4 md:border-r last:border-r-0 border-gray-300"
          >
            <div className="text-black flex items-center">{stat.icon}</div>
            <div className="flex flex-col md:items-start">
              <p className="text-xl font-bold text-black">{stat.value}</p>
              <p className="text-gray-600">{stat.label}</p>
              {stat.subLabel && <p className="text-gray-500 text-sm">{stat.subLabel}</p>}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Ribbon;
