import React from "react";

function ExclusiveGifts() {
  return (
    <div className="bg-white">
      {/* Main Content */}
      <div className="md:px-20 px-6 mx-auto md:py-16 py-10 flex flex-col md:flex-row items-center justify-between gap-20">
        {/* Sofa Image */}
        <div className="w-full md:w-1/2 flex flex-col justify-center">
          <div>
            <img src="/images/hero/dot-design.png" alt="img" />
          </div>

          <img
            src="/images/hero/sofa.png" // Replace with your image path
            alt="Sofa"
            className="z-50"
          />
        </div>

        {/* Text Content */}
        <div className="w-full md:w-1/2 text-left">
          <h2 className="text-[30px] font-semibold">
            Exclusive <span className="text-red-600">Gifts</span> with Design
          </h2>
          <p className="mt-4 text-gray-700 font-[600] md:text-[22px] text-lg">
            Explore our exclusive premium gifts, carefully selected to
            complement your stunning new space.
          </p>
          <p className="mt-2 text-gray-700 md:text-[22px] text-lg font-[600]">
            From home décor to personalized accessories, enjoy a thoughtful
            reward with every design.
          </p>

          <div className="flex justify-between">
            {/* Button */}
            <button className="mt-6 bg-red-600 text-white px-6 font-[600] py-2 rounded-full shadow-md hover:bg-red-700 transition duration-300">
              Call Us Now
            </button>

            {/* Pagination Dots */}
            <div className="mt-6 flex items-center space-x-2 mr-20">
              <div className="w-3 h-3 bg-gray-300 rounded-full"></div>
              <div className="w-3 h-3 bg-red-600 rounded-full"></div>
              <div className="w-3 h-3 bg-gray-300 rounded-full"></div>
              <div className="w-3 h-3 bg-gray-300 rounded-full"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative md:bottom-80 bottom-10">
        <img src="/images/hero/bottom-new.png" alt="bottom-image" />
      </div>

      {/* Curved Bottom Background */}
      {/* <div className="relative bottom-96">
        <svg
          viewBox="0 0 1440 320"
          className="w-full h-32 md:h-96"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#D02424"
            fillOpacity="1"
            d="M0,160 C320,100 640,240 960,220 C1280,200 1440,80 1440, 300 V320 H0 Z"
          ></path>
        </svg>
      </div> */}
    </div>
  );
}

export default ExclusiveGifts;
