import React from "react";

function OfferHero() {
  return (
    <div className="w-full h-screen bg-black bg-cover bg-center">
      {/* Content goes here */}
      <div className="flex md:flex-row flex-col-reverse justify-between">
        {/* left */}
        <div className="md:px-32 md:py-20 px-6">
          <p className="text-white font-[500] md:text-[18px] text-sm">OFFER</p>
          <h1 className="text-black md:text-[60px] text-[40px] font-bold inline-block mt-2 border-white"
            style={{
              textShadow:
                "-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff",
            }}
          >
            REDESIGN YOUR <br></br>
            <span className="inline-block relative px-2">
              SPACE &
              <span className="absolute inset-0 border-2 border-red-500 transform -rotate-12 p-4"></span>
            </span>{" "}
            <br></br>
            UNWRAP A GIFT
          </h1>
          <p className="text-white text-lg mt-6">Explore our Services</p>
        </div>

        {/* right: */}
        <div className="flex justify-end">
          <img
            className="relative md:bottom-7 bottom-4 md:h-80 h-40"
            src="/images/hero/chimney.png"
            alt="tag"
          />
          <img className="md:h-36 h-20" src="/images/hero/tag.gif" alt="tag" />
        </div>
      </div>

      <div className="flex justify-between relative">
        <img className="h-60 " src="/images/hero/ballon-left.png" alt="tag" />
        <img className="h-52" src="/images/hero/ballon-right.png" alt="tag" />
      </div>
    </div>
  );
}

export default OfferHero;
