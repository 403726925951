import React, {useState} from "react";
import Form from "../Form/Form";

const WhyChooseUs = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const features = [
    {
      icon: "💰",
      title: "Lowest Prices Guaranteed",
      description:
        "We provide the best possible solutions that suit your finances.",
    },
    {
      icon: "⏳",
      title: "Timely Delivery Assurance",
      description:
        "We proactively work on commitments to maintain our benchmark of ontime delivery.",
    },
    {
      icon: "🏅",
      title: "Flat 10-Year Warranty",
      description:
        "We invigorate client relationships by offering warranties that last a decade.",
    },
    {
      icon: "✔️",
      title: "Quality Checks At Every Step",
      description:
        "We guarantee thorough quality checks till project completion.",
    },
    {
      icon: "📑",
      title: "45 Days Moving Guarantee",
      description:
        "We create personalised spaces that cater to your every requirement.",
    },
  ];

  return (
    <section className="py-24 bg-gray-50 text-center">
      <h2 className="text-3xl font-semibold text-gray-900">Why Choose Us</h2>
      <p className="text-gray-600 mt-2 mb-8">
        With us, you experience the power of ideas, design and craftsmanship
        come alive.
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-8 max-w-6xl mx-auto">
        {features.map((feature, index) => (
          <div
            key={index}
            className="flex flex-col items-center text-center p-4 border-r last:border-r-0"
          >
            <span className="text-7xl text-pink-500 mb-4">{feature.icon}</span>
            <h3 className="font-semibold text-lg text-gray-900">
              {feature.title}
            </h3>
            {/* <p className="text-gray-600 text-sm mt-2">{feature.description}</p> */}
          </div>
        ))}
      </div>
      <div className="mt-8">
        <button onClick={() => setIsModalOpen(true)} className="bg-[#ff481f] text-white py-3 px-6 rounded-lg text-lg hover:bg-orange-600">
          Book Free Consultation
        </button>
      </div>

      <Form isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />

    </section>
  );
};

export default WhyChooseUs;
