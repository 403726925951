import React from "react";
import { Link } from "react-router-dom";

function Nav() {
  return (
    <div className="flex justify-between md:px-16 py-2 bg-[#D02424]">
      <a href="/">
        <img src="/images/home/interior-logo.png" alt="logo" />
      </a>

      <div className="flex items-center md:gap-10 gap-2">
        <Link to="/services">
          <button className="text-white md:text-md text-sm">
            Our Services
          </button>
        </Link>
        <Link to="/pincode">
          <button className="bg-white rounded-full font-[600] ms:px-6 px-2 md:text-md text-sm py-2 hover:bg-[#D02424] hover:text-white hover:border-white">
            Free Estimate
          </button>
        </Link>
      </div>
    </div>
  );
}

export default Nav;
