import React from 'react';

function Gifts() {
  return (
    <div className="py-20 md:px-20 px-4">
      {/* Heading Section */}
      <div className="flex md:flex-row flex-col justify-center items-center text-[24px] font-semibold">
        <p className="flex items-center md:text-[24px] text-lg md:tracking-widest">
          HOW TO CLAIM YOUR <span className="text-[#D02424] ml-1">FREE GIFT</span> ?
        </p>
        <img src="/images/hero/gift-box.gif" alt="gift" className="md:w-40 md:h-40 md:ml-2 w-20 h-20" />
      </div>

      {/* Steps Section */}
      <div className="md:mt-24 mt-16 flex md:flex-row flex-col justify-between gap-10 md:px-24 px-6">
        {/* Step 1 */}
        <div className='text-center md:w-72'>
          <p className="text-sm font-semibold">BOOK</p>
          <h3 className="font-[600] text-[#D02424] mt-2 md:text-[28px] text-[24px]">Book Your Interior Design Consultation</h3>
          <p className="text-gray-600 mt-2 text-md">Book your free consultation with our expert interior designers.</p>
        </div>

        {/* Step 2 */}
        <div className='text-center md:w-72'>
          <p className="text-sm font-semibold">CREATE</p>
          <h3 className="font-[600] text-[#D02424] mt-2 md:text-[28px] text-[24px]">Let Us Create Your Dream Design</h3>
          <p className="text-gray-600 mt-2 text-md">Collaborate with us to create your personalized dream design.</p>
        </div>

        {/* Step 3 */}
        <div className='text-center md:w-72'>
          <p className="text-sm font-semibold">ENJOY</p>
          <h3 className="font-[600] text-[#D02424] mt-2 md:text-[28px] text-[24px">Enjoy Your New Space & Free Gifts!</h3>
          <p className="text-gray-600 mt-2 text-md">Receive your free gift – furniture, appliances, and more, after completion!</p>
        </div>
      </div>
    </div>
  );
}

export default Gifts;
