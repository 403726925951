import React from "react";
import { Link } from "react-router-dom";

const Section1 = () => {
  return (
    <div>
      <header className="bg-white shadow-md">
        <div className="container mx-auto flex justify-between items-center py-4">
          <a href="/" className="flex items-center space-x-4">
            <img
              src="/im-long.png"
              alt=""
              className="rounded w-[170px] h-[50px]"
            />

            {/* <nav className="flex space-x-4">
              <a href="#" className="text-gray-700 hover:text-red-500 text-xs">
                HomeLane Luxe
              </a>
              <a href="#" className="text-gray-700 hover:text-red-500 text-xs">
                Design Gallery
              </a>
              <a href="#" className="text-gray-700 hover:text-red-500 text-xs">
                Modular Kitchen
              </a>
             
              <a href="#" className="text-gray-700 hover:text-red-500 text-xs">
                Bedroom
              </a>
              <a href="#" className="text-gray-700 hover:text-red-500 text-xs">
                Living Room
              </a>
              <a href="#" className="text-gray-700 hover:text-red-500 text-xs">
                Bathroom
              </a>
              
            </nav> */}
          </a>

          {/* Get Free Estimate Button */}
          <div className="ms-3">
            <Link to="/pincode" className="butn border rounded-pill bg-white">
              <span>Free Estimate</span>
            </Link>
          </div>

          {/* <div className="flex items-center space-x-6">
            <button className="px-4 py-2 bg-red-500 text-white rounded-md text-xs hover:bg-red-600">
              Get Free Estimate
            </button>
            <button className="relative text-gray-700 hover:text-red-500">
              <span className="absolute -top-2 -right-2 text-xs bg-red-500 text-white rounded-full px-1">
                0
              </span>
               <i className="fas fa-heart"></i> 
            </button>
          </div> */}
        </div>
      </header>
    </div>
  );
};

export default Section1;
