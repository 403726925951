import React, { useEffect } from 'react';
import Loader from '../../components/common/Loader';
import Footer from '../../components/home1/Footer';
import Section1 from '../../components/home1/Newhome/Section1';
import Menu from '../../components/innerpage/Menu';
import Navbar2 from '../../components/innerpage/Navbar2';
import StartButton from '../../components/home5_residence/StartButton';
import Header from '../../components/innerpage/contact/Header';
import ContactInfo from '../../components/innerpage/contact/ContactInfo';
import Map from '../../components/innerpage/contact/Map';
import ContactForm from '../../components/innerpage/contact/ContactForm';
import { Helmet } from 'react-helmet';
import Nav from '../../components/home1/Nav';

function ContactPage() {
  useEffect(() => {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    };

    loadScript("/common/assets/js/common_js.js").then(() => {
      loadScript("/innerpages/assets/js/innerpages.js");
    });

    return () => {
      // Cleanup scripts if necessary
    };
  }, []);

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          href="/innerpages/assets/css/innerpages.css"
        />
      </Helmet>
      <div className="inner-pages-style1 contact-pg-style1">
        <Loader />
        <Menu />
        <div className="smooth-scroll-content" id="scrollsmoother-container">
          {/* <Nav /> */}
          <Section1 />
          {/* <Header /> */}
          <main>
            {/* <ContactInfo /> */}
            {/* <Map /> */}
            <ContactForm />
          </main>
          <Footer />
        </div>
        <StartButton />
      </div>
    </>
  );
}

export default ContactPage;
