import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  p: 5,
};

function Experience() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <section className="tc-experience-style1 section-padding-x">
      <div className="container-fluid">
        <div className="row justify-content-between align-items-center">
          <div className="col-lg-2">
            <div className="exp-num mb-100 wow zoomIn" data-wow-delay="0.3s">
              <h5 className="fsz-18 text-uppercase">
                years of <br /> experience
              </h5>
              <h2 className="num"> 5 </h2>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="img wow">
              <img
                src="/home1/assets/img/home-section.jpg"
                alt="Experience in Indian Interior Design"
                className="img-cover"
              />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="info wow fadeInUp" data-wow-delay="0.3s">
              <h3 className="fsz-45 fw-600 mb-30"> Est. 2019 </h3>
              <div className="text fsz-15 color-666">
                Interior Master, based in the heart of Kolkata, specializes in
                crafting uniquely Indian interior spaces. With a perfect blend
                of contemporary aesthetics and traditional Bengali
                craftsmanship, we transform homes and offices into functional,
                culturally rich environments. Our team is passionate about
                bringing warmth and character to each space, celebrating
                Kolkata's vibrant heritage while incorporating modern design
                elements.
              </div>
              <button
                onClick={handleOpen}
                className="butn rounded-pill mt-50 hover-bg-black bg-white"
              >
                <span>
                  Our Studio <i className="small ms-1 ti-arrow-top-right"></i>
                </span>
              </button>
              <Modal open={open} onClose={handleClose} aria-labelledby="studio-video">
                <Box sx={style}>
                  {/* Close Button */}
                  <IconButton
                    onClick={handleClose}
                    sx={{ position: "absolute", top: 10, right: 10, color: "#000" }}
                  >
                    <CloseIcon />
                  </IconButton>

                  {/* Video Element */}
                  <video controls autoPlay className="w-full h-auto rounded-lg shadow-lg">
                    <source src="/images/video/studio.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </Box>
              </Modal>
            </div>
          </div>
          {/* <div className="col-lg-2">
            <div className="rotate-txt justify-content-lg-end">
              <ul>
                <li>
                  <a href="#"> support@interiormaster.in </a>
                </li>
                <li>
                  <a href="#"> +91 91230 77474 </a>
                </li>
              </ul>
            </div>
          </div> */}
        </div>
      </div>
      <img src="/home1/assets/img/c_line.png" alt="" className="c-line wow" />
    </section>
  );
}

export default Experience;
