import React from "react";

const PremiumGifts = () => {
  return (
    <div className="relative md:mt-0 mt-20 w-full h-screen bg-black flex items-center justify-center overflow-hidden bg-cover bg-center" style={{ backgroundImage: "url('/images/hero/premium-gift-bg.png')" }}>
      <div className="flex md:flex-row flex-col items-center mx-auto px-6 md:px-12">
        <div className="relative bottom-8">
          <img
            src="/images/hero/lux-sofa.png"
            alt="Couch"
            className="object-cover"
          />
        </div>
        <div className="flex flex-col gap-10 text-white space-y-4 relative bottom-10">
          <h1 className="md:text-6xl text-4xl font-[600] leading-[70px]">
            Enjoy Premium <span className="text-red-500">Gifts</span>
            <br /> Right After Design!
          </h1>
          <button className="flex items-center md:text-2xl text-lg">
            <span>EXPLORE OUR SERVICES</span>
            <span>→</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PremiumGifts;