import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ContactForm() {
  const servicesOptions = [
    "Modular Kitchen",
    "Storage and Wardrobe",
    "Crockery Units",
    "Space Saving Furniture",
    "TV Units",
    "Study Lamps",
    "False Ceiling",
    "Lights",
    "Wallpaper",
    "Wall Paint",
    "Bathroom",
    "Pooja Unit",
    "Foyer Designs",
    "Movable Furniture",
    "Kids Bedroom",
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    toast.success('Message sent successfully!');
    e.target.reset();
  };

  return (
    <section className="tc-contact-form-style1">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-5">
            <div className="info wow fadeInUp slow" data-wow-delay="0.2s">
              <h3 className="fsz-45 fw-500 mb-10">
                Let us helps build your dream!
              </h3>

              <div className="item mt-50">
                <div className='flex gap-2'>
                  <div>
                    <img className='w-16 h-16' src="/gharwala-interior-logo.png" alt="logo" />
                  </div>
                  <div>
                    <h2 className='text-4xl font-bold'>Interior Master</h2>
                    <h6 className="fsz-16 mb-15 font-bold text-[#cc0000]">(Gharwala Home Solution LLP) </h6>
                  </div>
                </div>
                <ul className="color-666 lh-6">
                  <li>
                    <a href="#"> 2, New Tollygunge, Purba Putiary, Kolkata, West Bengal 700093 </a>
                  </li>
                  <li>
                    <a href="#"> support@interiormaster.in </a>
                  </li>
                  <li>
                    <a href="#"> (054) 3256 78 87 </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <form
              onSubmit={handleSubmit}
              className="form mt-5 mt-lg-0 wow fadeInUp slow"
              data-wow-delay="0.4s"
            >
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group mb-30">
                    <label htmlFor="">
                      Full name <span className="color-orange1"> * </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="your name"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group mb-30">
                    <label htmlFor="">
                      email address <span className="color-orange1">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Your email address"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group mb-30">
                    <label htmlFor="">
                      Phone <span className="color-999"> (optional) </span>
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="Your phone number"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group mb-30">
                    <label htmlFor="">
                      Service <span className="color-orange1"> * </span>
                    </label>
                    <select 
                      name="service" 
                      className="form-select form-control p-2"
                      required
                    >
                      <option value="">Select a service</option>
                      {servicesOptions.map((service, index) => (
                        <option key={index} value={service}>{service}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group mb-30">
                    <label htmlFor=""> message </label>
                    <textarea
                      rows="6"
                      placeholder="Write your message here"
                      className="form-control"
                      name="message"
                    ></textarea>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="butn hover-bg-orange1 text-capitalize bg-white rounded-pill mt-40"
              >
                <span>
                  Send Your Message
                  <i className="fal fa-arrow-up-right ms-2"></i>
                </span>
              </button>
              <p className="fsz-12 color-666 mt-20">
                By summiting, i’m agree to the
                <a href="#" className="color-000 text-decoration-underline">
                  Terms & Conditions
                </a>
              </p>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer 
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <img
        src="/innerpages/assets/img/contact_shap.png"
        alt=""
        className="shap"
      />
    </section>
  );
}

export default ContactForm;