import React, { useState } from "react";
import { ArrowRight } from "lucide-react";
import { Modal, Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TwitterIcon from "@mui/icons-material/Twitter";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";

const ReferralSection = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <div className="bg-white py-12 md:px-32 px-6">
        <h2 className="text-4xl font-bold text-orange-600 mb-8">
          The Process Is Easy!
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mx-auto">
          {[
            {
              id: 1,
              text: "Start off with your friends and family",
              image: "/images/refer/refer-step1.png",
            },
            {
              id: 2,
              text: "Your referral confirms the booking",
              image: "/images/refer/refer-step2.png",
            },
            {
              id: 3,
              text: "Your referral pays 50% of the order value",
              image: "/images/refer/refer-step3.png",
            },
            {
              id: 4,
              text: "You stand to receive 100% of the referral bonus!",
              image: "/images/refer/refer-step4.png",
            },
          ].map((step) => (
            <div
              key={step.id}
              className="bg-white p-6 rounded-2xl shadow-md border-2 border-orange-200"
            >
              <div className="text-4xl font-bold text-orange-500 mb-4">
                {step.id}
              </div>
              <img
                src={step.image}
                alt={`Step ${step.id}`}
                className="mx-auto mb-4 h-40"
              />
              <p className="text-gray-700 text-lg text-center">{step.text}</p>
            </div>
          ))}
        </div>
        <div className="flex md:flex-row flex-col justify-between mt-20 md:bg-gradient-to-r bg-gradient-to-b from-orange-500 via-orange-300 to-orange-100 md:rounded-full rounded-xl md:px-20 px-6 md:py-0 py-6">
          <div className="text-white py-10 md:px-6 inline-block">
            <p className="text-3xl font-semibold">
              Spread the Word... Earn <span className="font-bold">₹11,000</span>{" "}
              on each successful referral
            </p>
          </div>
          <div className="flex items-center">
            <button onClick={handleOpen} className="flex gap-2 bg-orange-500 text-white py-3 px-6 rounded-xl shadow-md hover:bg-orange-600 transition-all">
              Refer Now <ArrowRight />
            </button>
          </div>
        </div>

        {/* Terms & Conditions Section */}
        <div className="mt-12 md:p-8 p-4 bg-gray-100 rounded-lg">
          <h3 className="text-2xl font-bold text-gray-800 mb-4">
            Terms & Conditions Apply
          </h3>
          <ul className="list-disc pl-6 text-gray-700 space-y-2">
            <li>
              • A successful referral is considered when the person referred
              confirms an order with Interior Company and pays the booking
              amount.
            </li>
            <li>
              • The ₹11,000 referral bonus will be processed only if the person
              referred pays 50% of the order value.
            </li>
            <li>
              • The payment will be credited to the account linked with the
              profile.
            </li>
            <li>
              • The minimum value of the referred order should be ₹4,00,000.
            </li>
            <li>
              • Only existing customers of Interior Company are eligible to
              refer.
            </li>
            <li>
              • Referral bonus cannot be combined or adjusted against any
              existing payment.
            </li>
            <li>
              • Interior Company holds the right to revoke all offers at its
              sole discretion without any prior notice.
            </li>
          </ul>
        </div>
      </div>

      {/* Model: */}
      <Modal open={open} onClose={handleClose}>
        <Box
          className="bg-white p-6 rounded-lg shadow-xl w-96 mx-auto mt-20 relative"
          sx={{ outline: "none", borderRadius: "10px" }}
        >
          {/* Close Button */}
          <IconButton
            sx={{ position: "absolute", top: 8, right: 8, color: "orange" }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>

          {/* Modal Content */}
          <div className="border-b pb-2 mb-4">
            <span className="text-black text-lg font-semibold">
              Referral Link
            </span>
          </div>

          <div className="text-gray-700 mb-2">Generate your referral link:</div>

          <input
            type="text"
            placeholder="Your Phone Number"
            className="w-full border px-3 py-3 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
          />

          <button className="w-full bg-orange-500 hover:bg-orange-600 text-white py-3 rounded-md font-medium mt-3 transition duration-300">
            GENERATE LINK
          </button>

          <div className="text-gray-600 mt-2">Share the good word</div>

          {/* Social Media Icons */}
          <div className="flex justify-center gap-4 mt-2">
            <IconButton sx={{ color: "#ff481f", bgcolor: "#f5f5f5" }}>
              <FacebookIcon />
            </IconButton>
            <IconButton sx={{ color: "#ff481f", bgcolor: "#f5f5f5" }}>
              <TwitterIcon />
            </IconButton>
            <IconButton sx={{ color: "#ff481f", bgcolor: "#f5f5f5" }}>
              <WhatsAppIcon />
            </IconButton>
            <IconButton sx={{ color: "#ff481f", bgcolor: "#f5f5f5" }}>
              <EmailIcon />
            </IconButton>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ReferralSection;
